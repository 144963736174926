import React from 'react';

const TermsOfUsePage = () => {
  return (
    <div className="terms_wrapper">
      <h1 className="termsTitle">Terms of use</h1>
      <h3 className="terms_h3"><i>Version as of 25. March 2019</i></h3>

      <h2>1. Welcome to MINDWORX Academy</h2>
      <div className="terms_content">
        <p className="terms_miniTitle"> 1.1 Introduction: </p>
        MINDWORX s. r. o. (hereinafter as “<b>MINDWORX</b>”,
        “<b>MW</b>”, “<b>we</b>”, “<b>us</b>”, “<b>our</b>”)
        provides its services (described below) to you through its website located at www.mindworxacademy.com, as well as through websites of its partners (any and all of the foregoing websites hereinafter as the “
        <b>Site</b>
        ”) and through their mobile applications and related services (collectively, such services, including any new features, upgrades, applications, and the Site, hereinafter as the “
        <b>Service(s)</b>
        ”), subject to the following Terms of Use (hereinafter the “
        <b>Terms</b>
        ”) and subject to any terms of use imposed by the relevant Site.
        <b>
          <br />
          <br />
          Please read these Terms carefully, as they govern your use of the Site and Services.
        </b>
        <br />
        <br />

        <p className="terms_miniTitle">1.2 Modifications to the Terms:</p>
        We reserve the right, at our sole discretion, to amend or supplement these Terms at any time. If we do this, depending on
        the nature of the change, we will post the changes on this page and indicate at the top of this page the date these terms
        were last revised and/or notify you, either through the Services' user interface, via email notification or through other
        reasonable means, and as required by applicable law. Any such changes will become effective no earlier than fourteen (14)
        days after they are posted, except that changes addressing new functions of the Services or changes made for legal reasons
        will be effective immediately as permitted by applicable law. Your continued use of the Service after the date any such changes
        become effective constitutes your acceptance of the new Terms. In addition, when using the Services, you will be subject to any
        additional terms applicable to such Services that may be from time to time applicable to the use of such Service.
        <p className="terms_miniTitle">1.3 Privacy: </p>
        We all at MW respect the privacy of our users. For details please see our Privacy Policy. By using the Services, you provide your consent to our collection and use of personal data as outlined therein.
      </div>
      <h2>2. Access and Use of the Service</h2>
      <div className="terms_content">
        <p className="terms_miniTitle">2.1 Use Description: </p>
        The MW service, as well as any content accessed via our service, is solely for your personal and non-commercial use. With your purchase of the Services we grant you a limited, non-exclusive, non-transferable, license to access the Services’ content and view your course(s) through the service on a streaming-only basis and through any downloadable content available at the Site for that purpose. Except for the foregoing limited license, no right, title or interest whatsoever shall be transferred to you. You agree not to use the Service for public performances. MW may revoke your license at any time in its sole discretion. Upon
        such revocation, you must promptly destroy all content downloaded or otherwise  obtained through the Service, as well as copies of such materials, whether made in accordance with these Terms or otherwise.
        <p className="terms_miniTitle">2.2 Your Registration Obligations: </p>
        You may be required to register with MW in order to access and use certain features of the Service. If you choose to register for the Service, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Service’s registration form. Registration data and certain other information about you are governed by our Privacy Policy.
        <p className="terms_miniTitle">
          2.3 Member Account, Password and Security:{' '}
        </p>
        You may never use another’s account, and you may not provide another person with the username and password to access your own account. You are fully responsible for any and all activities that occur under your password or account, and it is your responsibility to ensure that your password remains confidential and secure. You agree to (i) immediately notify MW of any unauthorised use of your password or account or any other breach of security, and (ii) ensure that you exit from your account at the end of each session when accessing the Service. MW will not be liable for any loss or damage arising from your failure to comply with this Clause. If your subscription is part of a corporate licence, you agree that you will create your own personal account for the Service and will abide by these Terms and terms of each Site via which you access the Service.
        <p className="terms_miniTitle">2.4 Modifications to Service: </p>
        reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that MW will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service. We have no obligation to retain any data relating to of your account or any submitted content for any period of time beyond what may be required by applicable law.
        <p className="terms_miniTitle">
          2.5 General Practices Regarding Use and Storage:{' '}
        </p>
        You acknowledge that MW may establish general practices and limits concerning use of the Service, including without limitation the maximum period of time that data or other content will be retained by the Service and the maximum storage space that will be allotted on the Site and its servers on your behalf. You agree that MW has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded by the Service. You acknowledge that MW reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that MW reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.
        <p className="terms_miniTitle">2.6 Mobile Services: </p>
        The Service may include certain services that are available via mobile device, including (i) the ability to upload content to the Service via mobile device, (ii) the ability to browse the Service and the Site from a mobile device and (iii) the ability to access certain features through an application downloaded and installed on a mobile device (hereinafter the “
        <b>Mobile Services</b>
        ”). To the extent you access the Service through a mobile device, your wireless service provider’s standard charges, data rates and other fees may apply. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your provider and not all Mobile Services may work with all providers or devices. When using the Mobile Services, you may be required to provide your telephone number. By providing your telephone number, you consent to receive calls and/or SMS, MMS, or text messages at that number. We may share your phone numbers with our affiliates or with our service providers (such as customer support, billing or collections companies, and text message service providers) who we have contracted with to assist us in pursuing
        our rights or providing our Services under these Terms, our policies, applicable law, or any other agreement we may have with you from time to time. You agree these parties may also contact you using calls and text messages, as authorised by us to carry out the purposes we have identified above, and not for their own purposes. In the event you change or deactivate your mobile telephone number, you agree to promptly update your Service account information to ensure that your messages are not sent to the person that acquires your old number.
        <p className="terms_miniTitle">2.7 Money Back Guarantee: </p>
        In case you become dissatisfied with the Service within the first 14 days after purchase, MW will refund the full amount of your purchase and subsequently terminate your access. For purchases using any discount, promotional or other similar offers MW will refund as part of the money back guarantee only the actual sum paid by you for the respective Service. Refunds are not available for customers 14 days after a purchase. Refunds are not available for accounts which have violated these Terms of Use; violations are determined at MW’s sole discretion. If MW determines that you are abusing our refund policy, we reserve the right to suspend or terminate your account and refuse or restrict any and all current or future use of the Service without delivering a refund. To request a refund or to ask a question regarding the 14-day money back guarantee, please send your email to moneyback@mindworxacademy.com. The money back guarantee is not available to any users subscribed as part of a corporate licence (i.e. licence not purchased by individual user).
      </div>

      <h2> 3. Conditions of Use </h2>
      <div className="terms_content">
        <p className="terms_miniTitle">
          3.1 User Conduct:
        </p>
        You are solely responsible for all code, video, images, information, data, text, software, music, sound, photographs, graphics, messages or other materials (“
        <b>content</b>
        ”) that you upload, post, publish or display (hereinafter, “
        <b>upload</b>
        ”) or email or otherwise use via the Service. MW reserves the right to investigate and take appropriate legal action against anyone who, in MW’s sole discretion, violates this provision, including without limitation, removing the offending content from the Service, suspending or terminating the account of such violators and reporting you to the law enforcement authorities. You agree not to use the Service to:  - email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or other relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorised advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libellous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of MW is objectionable or which restricts or inhibits any other person from using or enjoying the Service, or which may expose MW or its users to any harm or liability of any type;
        - interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service;  - violate any applicable national, European or international law, or any regulations having the force of law; - impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity; solicit personal information from any other person; - collect email addresses or other contact information of other users from the Service by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications; - advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorised by MW; - further or promote any criminal activity or enterprise or provide instructional information about illegal activities; or - obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Service.
        <br />
        <p className="terms_miniTitle">3.2 Fees: </p>
        To the extent the Service or any part thereof is made available for any fee, you will be required to select a payment plan and provide MW information regarding your debit card, credit card or other payment instrument. You represent and warrant to MW that such information is true and that you are authorised to use the payment instrument. You will promptly update your account information with any changes (for example, a change in your billing address or debit/credit card expiration date) that may occur. You agree to pay MW the amount that is specified in the payment plan in accordance with the terms of such plan and these Terms of Use. You hereby authorise MW to bill your payment instrument in accordance with the terms of the applicable payment plan until the whole amount of fee is duly paid by you to MW, and you further agree to pay any charges so incurred. If you dispute any charges you must let MW know within sixty (60) days after the date when MW charges you. We reserve the right to change Services’ prices.
        <p className="terms_miniTitle">
          3.3 Special Notice for International Use:
        </p>
        Downloading or using the Software (defined below) is at your sole risk. Recognising the global nature of the Internet, you agree to comply with all local rules and laws regarding your use of the Service, including as it concerns online conduct and acceptable content.
        <p className="terms_miniTitle">3.4 Commercial Use: </p>
        Unless otherwise expressly authorised herein or by MW in writing, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the Service, use of the Service, or access to the Service.
      </div>
      <h2>4. Intellectual Property Rights</h2>
      <div className="terms_content">
        <p className="terms_miniTitle">
          4.1 Service Content, Software and Trademarks:{' '}
        </p>
        You acknowledge and agree that the Service may contain content or features (“
        <b>Service Content</b>
        ”) that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorised by MW, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Service Content, in whole or in part. In connection with your use of the
        Service you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by MW from accessing the Service (including blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Service or the Service Content other than as specifically authorised herein is strictly prohibited. The technology and software underlying the Service or distributed in connection therewith (the “
        <b>Software</b>
        ”) are the property of MW, our affiliates and our partners. You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by MW. The MW name and logos are trademarks and service marks of MW (collectively the “
        <b>MW</b>
        <b>Trademarks</b>
        ”). Other MW, product, and service names and logos used and displayed via the Service may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to MW. Nothing in these Terms of Use or the Service should be construed as granting, by implication, expressly or otherwise, any license or right to use any of MW Trademarks displayed on the Service. All goodwill generated from the use of MW Trademarks will inure to our exclusive benefit.
        <p className="terms_miniTitle">4.2 Third Party Material: </p>
        Under no circumstances will MW be liable in any way for any content or materials of any third parties (including users), including, but not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that MW does not pre-screen content, but that MW and its designees will have the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Service. Without limiting the foregoing, MW and its designees will have the right to remove any content that violates these Terms of Use or is deemed by MW, in its sole discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content.
        <p className="terms_miniTitle">
          4.3 User Content Transmitted Through the Service:{' '}
        </p>
        With respect to the content or other materials you upload through the Service or share with other users or recipients (collectively, “
        <b>User Content</b>
        ”), you represent and warrant that you own all right, title and interest in and to such User Content, including, without limitation, all copyrights and rights of publicity contained therein, and that you have all required rights to post or transmit such content or other materials without violation of any third-party rights. By uploading any User Content you hereby grant and will grant MW, its affiliated companies and partners a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, adapt, perform, publish, distribute (through multiple tiers of distribution and partnerships), store, modify and otherwise use and fully exploit your User Content in any and all media, form, medium, technology or distribution methods now known or later developed and for any and all purposes (commercial or otherwise). You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information relevant to the Service (“
        <b>Submissions</b>
        ”), provided by you to MW, its affiliated companies or partners are non-confidential and MW, its affiliated companies and partners will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You acknowledge and agree that MW may preserve content
        and may also disclose content if required by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (i) comply with legal process, applicable laws or government requests; (ii) enforce these Terms Use; (iii) respond to claims that any content violates the rights of third parties; or (iv) protect the rights, property, or personal safety of MW, its users and the public.  You understand that the technical processing and transmission of the Service, including your content, may involve (i) transmissions over various networks, and (ii) changes to conform and adapt to technical requirements of connecting networks or devices.
        <p className="terms_miniTitle">4.4 Copyright Complaints: </p>
        MW respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify MW of your infringement claim in accordance with the procedure set forth below. You may also contact us by mail at: info@mindworxacademy.com  To be effective, the notification must be in writing and contain the following information:  - a description of the copyrighted work or other intellectual property that you claim has been infringed;  - a description of where the material that you claim is infringing is located on the Service, with enough detail that we may find it on the Service;  - your address, telephone number, and email address;  - a statement by you that you have a good faith belief that the disputed use is not authorised by the copyright or intellectual property owner, its agent, or the law;  - a declaration of oath by you that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorised to act on behalf of the copyright or intellectual property owner.
        <p className="terms_miniTitle">4.5 Counter-Notice: </p>
        If you believe that your User Content that was removed (or access to which was disabled) is not infringing, or that you have the authorisation from the copyright owner, the copyright owner’s agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to us:  - identification of the content that has been removed or access to which has been disabled and the location at which the content appeared before it was removed or disabled;  - a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content;  - your name, address, telephone number and email address, a statement that you consent to the jurisdiction of the relevant courts of the Slovak Republic. If a counter-notice is received by us, MW will send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion.
        <p className="terms_miniTitle">4.6 Repeat Infringer Policy: </p>
        In accordance applicable law, MW has adopted a policy of terminating, in appropriate circumstances and at MW’s sole discretion, users who are deemed to be repeat infringers. MW may also at its sole discretion limit access to
        the Service and/or terminate the memberships of any users who infringe any intellectual property rights, whether or not there is any repeat infringement.
      </div>
      <h2>5. Third Party Websites </h2>
      <div className="terms_content">
        The Service may provide, or third parties may provide, links or other access to other sites and resources on the Internet. MW has no control over such sites and resources and MW is not responsible for and does not endorse such sites and resources. You further acknowledge and agree that MW will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such site or resource. Any dealings you have with third parties while using the Service are between you and the third party, and you agree that MW is not liable for any loss or claim that you may have against any such third party.
      </div>
      <h2>6. Indemnity and Defence </h2>
      <div className="terms_content">
        To the fullest extent permitted by law, you agree to defend, indemnify and hold MW and its affiliates and their officers, employees, directors and agents harmless from any and all losses, damages, expenses, including reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Service, any User Content, your connection to the Service, your violation of these Terms of Use or your violation of any other rights whatsoever.
      </div>
      <h2>7. Disclaimer of Warranties </h2>
      <div className="terms_content">
        Your use of Services is at your sole risk. The Services are provided on an “as is” and “as available” basis. Except as otherwise expressly provided herein, MW expressly disclaims all warranties of any kind, whether express, implied or statutory, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. MW makes no warranty that (i) the Services will meet your requirements, (ii) the Services will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the Services will be accurate or reliable, or (iv) the quality of any products, Services, information, or other material purchased or obtained by you through the Services will meet your expectations.
      </div>
      <h2>8. Limitation of Liability </h2>
      <div className="terms_content">
        You expressly accept and agree that MW will not be liable for any indirect, incidental >special, consequential, exemplary damages, or damages for loss of profits including but not limited to, damages for loss of goodwill, use, data or other intangible losses (even if MW has been advised of the possibility of such damages), whether based on contract, non-contractual liability, negligence, strict liability or otherwise, resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of
        substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorised access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the Services; (v) application of and reliance on information obtained from the Service; or (vi) any other matter relating to the Services. In no event will MW’s total liability to you for any and all damages, losses or causes of action exceed the amount you have paid to MW for access to the Services at the time of purchase of the respective Service.
      </div>
      <h2>9. Termination </h2>
      <div className="terms_content">
        You agree that MW, in its sole discretion, may suspend or terminate your account (or any part thereof) or your use of the Services and remove and discard any content within the Service, for any reason, including, without limitation, for lack of use or if MW believes that you have violated or acted inconsistently with the letter and/or spirit of these Terms of Use. Any suspected fraudulent, abusive or illegal activity that may form grounds for termination of your use of Services, may be referred to appropriate law enforcement authorities. MW may also in its sole discretion and at any time discontinue providing the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of these Terms of Use may be effected without prior notice, and acknowledge and agree that MW may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services. Further, you agree that MW will not be liable to you or any third party for any termination of your access to the Services.
      </div>
      <h2>10. Disputes Between Users </h2>
      <div className="terms_content">
        You agree that you are solely responsible for your interactions with any other user in connection with the Services and MW will have no liability or responsibility with respect thereto. MW reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Services.
      </div>
      <h2>11. Certificate </h2>
      <div className="terms_content">
        In order to receive a certification of the chosen Service’s completion, you need to finish an entire online course and pass the final exam. In order to pass the final exam, you need to acquire minimum 70% pass grade. The final exam might be taken only three times per each user. If you fail the final exam all three times, you need to apply for re-enrolment via info@mindworxacademy.com to retake the test.
      </div>
      <h2>12. General </h2>
      <div className="terms_content">
        These Terms of Use constitute the entire agreement between you and MW and govern your use of the Service, superseding any prior agreements between you and MW with respect to the Services. You also may be subject to additional terms and

        conditions that may apply when you use affiliate or third party services, third party content or third party software. These Terms of Use are governed by the laws of Slovakia without regard to its conflict of law provisions. With respect to any disputes or claims, you and MW agree to submit to the exclusive jurisdiction of the applicable Slovak courts. The failure of MW to exercise or enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms of Use shall remain in full force and effect.  You may not assign these Terms of Use (including any rights and/or obligations hereunder) without the prior written consent of MW, but MW may assign or transfer these Terms of Use (including any rights and/or obligations hereunder), in whole or in part, without restriction. The titles of clauses in these Terms of Use are for convenience only and have no legal or contractual effect. Notices to you may be made via either email or mail. Under no circumstances shall MW be held liable for any delay or failure in performance resulting directly or indirectly from an event beyond its reasonable control. MW may also provide notices to you of changes to these Terms of Use or other matters by displaying notices or links to notices generally on the Site.
      </div>
      <h2>13. Questions? Concerns? Suggestions? </h2>
      <div className="terms_content">
        Please contact us at
        <a href="mailto:info@mindworxacademy.com">
          info@mindworxacademy.com
        </a>
        to report any violations of these Terms of Use or to pose any questions regarding these Terms of Use or the Services.
      </div>
    </div>
  );
};

export default TermsOfUsePage;
